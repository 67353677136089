import React, { useContext, useState } from "react";
import Seo from "../components/seo";
import * as classes from "./scavenger-hunt.module.css";
import headerCheck from "../images/image-header-check.svg";
import headerX from "../images/image-header-x.svg";
import completeX from "../images/image-complete-x.svg";
import Checkbox from "../components/scavenger-hunt/Checkbox";
import ParentRouteHandler from "../components/ParentRouteHandler";
import { StoreContext } from "../store";

const ScavengerHunt = ({ location }) => {
  const { scavengerHunt } = useContext(StoreContext);
  const [progress, setProgress] = useState(false);

  const scavengerProgress = (event, id) => {
    const scavIndex = scavengerHunt.items.findIndex((p) => {
      return p.id === id;
    });

    const checkboxStates = {
      ...scavengerHunt.items[scavIndex],
    };

    checkboxStates.checked = !checkboxStates.checked;
    scavengerHunt.items[scavIndex] = checkboxStates;

    let counter = 0;
    scavengerHunt.items.map((item) => {
      if (!item.checked) {
        counter++;
      }
    });

    if (counter === 0) {
      setProgress(true);
    } else {
      setProgress(false);
    }
  };

  const backToScav = () => {
    setProgress(false);
  };

  return (
    <>
      <Seo title="Scavenger Hunt" />
      {location.state &&
      location.state.originalPath &&
      location.state.originalPath === "home" ? (
        <ParentRouteHandler title="Home" path="/" />
      ) : (
        <ParentRouteHandler title="For Families" path="/for-families" />
      )}

      <div className={classes.ScavengerHeader}>
        <img
          className={classes.ScavengerHeaderCheck}
          src={headerCheck}
          alt="check"
        />

        {progress ? (
          <>
            <h1 className={classes.ScavengerTitle}>
              GREAT WORK EXPLORER!<span>CLAIM YOUR PRIZE!</span>
            </h1>
            <img
              className={classes.ScavengerHeaderDottedLineComplete}
              src={completeX}
              alt="dotted lines"
            />
          </>
        ) : (
          <>
            <h1>THE BROAD SCAVENGER HUNT</h1>
            <img
              className={classes.ScavengerHeaderDottedLine}
              src={headerX}
              alt="dotted lines"
            />
          </>
        )}
      </div>

      <div className={classes.ScavengerBody}>
        {progress ? (
          <p className={classes.ScavengerBodyCenter}>
            Show this screen or your completed check list to a Visitor
            Experience Team Member stationed in the gallery to claim your prize!
          </p>
        ) : (
          <p>
            Ask any Visitor Experience Team Member for help! For a contactless
            experience, text us at 213-232-6280 or chat with us live.
          </p>
        )}
      </div>

      <div>
        {progress ? (
          <p className={classes.ScavengerBackLink} onClick={backToScav}>
            Back to scavenger hunt
          </p>
        ) : (
          scavengerHunt.items.map((item, index) => {
            return (
              <Checkbox
                key={index}
                id={item.id}
                text={item.text}
                checked={item.checked}
                click={(event) => scavengerProgress(event, item.id)}
              />
            );
          })
        )}
      </div>
    </>
  );
};

export default ScavengerHunt;
