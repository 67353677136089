import React, { useContext } from "react";
import * as classes from "./Checkbox.module.css";
import { StoreContext } from "../../store";

const Checkbox = ({ text, id, checked, click }) => {
  const { scavengerHunt, markScavenger } = useContext(StoreContext);

  let checkboxClasses = [classes.CheckboxContainer];

  const toggleCheck = (e) => {
    click(e, id);
    markScavenger(id, scavengerHunt.items[id]);
  };

  if (checked) {
    checkboxClasses.push(classes.CheckboxActive);
  }

  return (
    <div
      className={checkboxClasses.join(" ")}
      scav-status={checked ? "active" : ""}
    >
      <input id={id} type="checkbox" onClick={(e) => toggleCheck(e)} />
      <label htmlFor={id}>
        <span>{text}</span>
      </label>
    </div>
  );
};

export default Checkbox;
